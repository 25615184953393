import { template as template_15f8d47ef5704fe9a97804423377ba15 } from "@ember/template-compiler";
const FKLabel = template_15f8d47ef5704fe9a97804423377ba15(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
