import { template as template_38816e28e598486091a762561e6123b2 } from "@ember/template-compiler";
const FKControlMenuContainer = template_38816e28e598486091a762561e6123b2(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
