import { template as template_c5f39955952048a2bcaa6f6eff0253b7 } from "@ember/template-compiler";
const SidebarSectionMessage = template_c5f39955952048a2bcaa6f6eff0253b7(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
