import { template as template_09770579eb3249118ba34f1e6ed9a1bd } from "@ember/template-compiler";
const WelcomeHeader = template_09770579eb3249118ba34f1e6ed9a1bd(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
